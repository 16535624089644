import React, { useEffect, useRef, useState } from "react";
import TileAnnotationWindow from "./TileAnnotationWindow";
import { ColorButton } from "../../helpers/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import { annotationSeverityOptions } from "../../helpers/Constants";
import { showNotificationToast } from "../../helpers/Messages";

const AnnotationWindow = ({
  selectedImageDetails,
  setAnnotationWindowLoad,
}) => {
  const canvasRef = useRef();
  const annotationContainerRef = useRef(null);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [showThermal, setShowThermal] = useState(false);
  const [loadingThermalData, setLoadingThermalData] = useState(false);

  const [panning, setPanning] = useState(false);
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });

  const [zoomValue, setZoomValue] = React.useState(1);
  const prevZoomValueRef = useRef(zoomValue);

  const [hoveredCell, setHoveredCell] = useState({
    row: 0,
    col: 0,
    pixelX: 0,
    pixelY: 0,
    val: 0,
  });
  const [dots, setDots] = useState("");
  const [matrix, setMatrix] = useState([]);

  const fetchMatrix = async () => {
    setLoadingThermalData(true);
    try {
      const response = await window.apiHelper.getThermalData(
        selectedImageDetails?.id
      );
      setMatrix(response.thermal_array);
    } catch (error) {
      setMatrix([]);
      setShowThermal(false);
      showNotificationToast("Error try again !");
      console.error("Error fetching matrix data:", error);
    } finally {
      setLoadingThermalData(false);
    }
  };

  useEffect(() => {
    if (loadingThermalData) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length === 3 ? "" : prevDots + "."));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loadingThermalData]);

  useEffect(() => {
    // if (prevZoomValueRef.current !== zoomValue) {
    //   setPanStart({ x: 0, y: 0 });
    //   setPanOffset({ x: 0, y: 0 });
    // }
    const drawCanvas = () => {
      const canvas = canvasRef.current;

      if (!canvas || !selectedImageDetails) {
        return;
      }

      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = selectedImageDetails.file;

      const container = canvas.parentElement;

      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      const draw = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const offsetX = panOffset.x;
        const offsetY = panOffset.y;

        ctx.drawImage(
          image,
          offsetX,
          offsetY,
          canvas.width * zoomValue,
          canvas.height * zoomValue
        );

        if (annotationContainerRef.current) {
          if (selectedImageDetails.annotated && showAnnotations) {
            const transformedArray = selectedImageDetails.annotations.map(
              (item) => {
                return {
                  h: item.data.h,
                  w: item.data.w,
                  x: item.data.x,
                  y: item.data.y,
                  imageWidth: item.data.imageWidth,
                  imageHeight: item.data.imageHeight,
                  message: `${item.feature}: ${item.defect.join(", ")}`,
                  severity: item.severity,
                };
              }
            );

            let allAnnotationContent = "";
            transformedArray.forEach((region) => {
              let option = annotationSeverityOptions.find(
                (option) => option.id === region.severity
              );
              let color = option.color;

              ctx.strokeStyle = color;
              ctx.lineWidth = 4;

              const scaleX = (canvas.width / region.imageWidth) * zoomValue;
              const scaleY = (canvas.height / region.imageHeight) * zoomValue;

              const rectX = region.x * scaleX + offsetX;
              const rectY = region.y * scaleY + offsetY;
              const rectW = region.w * scaleX;
              const rectH = region.h * scaleY;

              ctx.strokeRect(rectX, rectY, rectW, rectH);

              let fill = option.fill;

              ctx.fillStyle = fill;
              ctx.fillRect(rectX, rectY, rectW, rectH);
              const isVisible =
                rectX + rectW >= 0 &&
                rectX + rectW <= canvas.width &&
                rectY + rectH / 6 >= 0 &&
                rectY + 50 <= canvas.height;
              const annotationClassName = isVisible
                ? determineAnnotationClassName(region)
                : "hidden-box";

              // Update the content of the annotation container
              const annotationContent = `<div class=${annotationClassName} style="position:absolute; left: ${
                rectX + rectW / 2.5
              }px; top: ${rectY + rectH / 6}px;">${region.message}</div>`;

              allAnnotationContent += annotationContent;
            });

            // Update the content of the annotation container after the loop
            annotationContainerRef.current.innerHTML = allAnnotationContent;
          } else {
            annotationContainerRef.current.innerHTML = "";
          }
        }

        requestAnimationFrame(draw);
      };
      image.onload = () => {
        draw();
        setAnnotationWindowLoad(false);
        prevZoomValueRef.current = zoomValue;
      };
    };

    drawCanvas();
  }, [selectedImageDetails, showAnnotations, panOffset, zoomValue]);

  useEffect(() => {
    setZoomValue(1);
    setPanStart({ x: 0, y: 0 });
    setPanOffset({ x: 0, y: 0 });
    setShowThermal(false);
    setMatrix([]);
    setLoadingThermalData(false);
  }, [selectedImageDetails]);

  const determineAnnotationClassName = (region) => {
    // Example: Determine className based on severity level
    const severity = region.severity;
    switch (severity) {
      case 1:
        return "annotation-box-good-severity";
      case 2:
        return "annotation-box-low-severity";
      case 3:
        return "annotation-box-medium-severity";
      case 4:
        return "annotation-box-high-severity";
    }
  };

  const toggleAnnotations = () => {
    setShowAnnotations((prevShowAnnotations) => !prevShowAnnotations);
  };

  const toggleThermal = () => {
    setShowThermal((prevShowThermal) => !prevShowThermal);
    if (!showThermal) {
      matrix.length === 0 && fetchMatrix();
    }
  };

  const handleMouseDown = (e) => {
    setPanning(true);
    setPanStart({ x: e.clientX - panOffset.x, y: e.clientY - panOffset.y });
  };

  const handleMouseMove = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = Math.floor((e.clientX - rect.left - panOffset.x) / zoomValue);
    const y = Math.floor((e.clientY - rect.top - panOffset.y) / zoomValue);
    const col = Math.floor(
      x / (canvasRef.current.parentElement.clientWidth / 640)
    ); // Adjusted for grid item size
    const row = Math.floor(
      y / (canvasRef.current.parentElement.clientHeight / 512)
    );

    matrix.length !== 0 &&
      setHoveredCell({
        row: row,
        col: col,
        pixelX: x,
        pixelY: y,
        val: matrix[row][col],
      });
    if (panning) {
      const offsetX = e.clientX - panStart.x;
      const offsetY = e.clientY - panStart.y;

      if (
        offsetX < 0 &&
        offsetX > canvasRef.current.width * -1 * (zoomValue - 1) &&
        offsetY < 0 &&
        offsetY > canvasRef.current.height * -1 * (zoomValue - 1)
      ) {
        setPanOffset({ x: offsetX, y: offsetY });
      }
    }
  };

  const handleMouseUp = (e) => {
    setPanning(false);
  };

  const handleWheel = (e) => {
    const cursorX = e.nativeEvent.offsetX;
    const cursorY = e.nativeEvent.offsetY;

    const delta = e.deltaY;
    const zoomFactor = 0.35;
    const newZoomValue = zoomValue + (delta > 0 ? -zoomFactor : zoomFactor);

    if (newZoomValue >= 1 && newZoomValue <= 6) {
      const offsetX =
        cursorX - (cursorX - panOffset.x) * (newZoomValue / zoomValue);
      const offsetY =
        cursorY - (cursorY - panOffset.y) * (newZoomValue / zoomValue);

      setZoomValue(newZoomValue);
      {
        newZoomValue === 1
          ? setPanOffset({ x: 0, y: 0 })
          : setPanOffset({ x: offsetX, y: offsetY });
      }
    }
  };

  return (
    <>
      {selectedImageDetails ? (
        selectedImageDetails?.is_tiled ? (
          <TileAnnotationWindow
            selectedImageDetails={selectedImageDetails}
            setAnnotationWindowLoad={setAnnotationWindowLoad}
          />
        ) : (
          <>
            <canvas
              ref={canvasRef}
              width="100%"
              height="100%"
              style={{
                display: "block",
                boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
                cursor: showThermal
                  ? "crosshair"
                  : panning
                  ? "grabbing"
                  : "grab",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onWheel={handleWheel}
            />
            {selectedImageDetails?.annotated && (
              <ColorButton
                onClick={toggleAnnotations}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
              >
                {showAnnotations ? "Hide" : "Show"} Annotations
              </ColorButton>
            )}
            {selectedImageDetails?.type === 4 &&
              (selectedImageDetails?.meta_data?.is_thermal_processed !==
                undefined ||
                selectedImageDetails?.meta_data?.is_thermal_processed) && (
                <ColorButton
                  onClick={toggleThermal}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: selectedImageDetails?.annotated ? "165px" : "5px",
                  }}
                >
                  {loadingThermalData ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    `${showThermal ? "Hide" : "Show"} Thermal`
                  )}
                </ColorButton>
              )}
            {showThermal && (
              <div
                className="info"
                style={{
                  position: "absolute",
                  bottom: "5px",
                  left: "-135px",
                  width: "200px",
                }}
              >
                {loadingThermalData ? (
                  <span>Temp: Loading{dots}</span>
                ) : (
                  <span>
                    Temp: {matrix[hoveredCell.row][hoveredCell.col].toFixed(2)}{" "}
                    °C
                  </span>
                )}
              </div>
            )}

            {selectedImageDetails?.type === 4 &&
              (selectedImageDetails?.meta_data?.is_thermal_processed ===
                undefined ||
                !selectedImageDetails?.meta_data?.is_thermal_processed) && (
                <div
                  className="info"
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    left: "-135px",
                    width: "200px",
                  }}
                >
                  Temp: No Data
                </div>
              )}

            <div ref={annotationContainerRef} />
          </>
        )
      ) : (
        <div className="select-image">Select an image</div>
      )}
    </>
  );
};

export default AnnotationWindow;
