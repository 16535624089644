import React, { useState, useEffect, useRef } from "react";
import InspectionCard from "../../Components/MainContent/InspectionCard";
import InspectionsListMap from "./InspectionsListMap";
import Sort from "../../assets/img/icons/sort.png";
import Reset from "../../assets/img/icons/reset.png";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Header from "../../helpers/Header";
import SingleDropdown from "../../helpers/SingleDropdown";
import { inspectionsSortOptions } from "../../helpers/Constants";
import InfiniteScroll from "../../helpers/InfiniteScroll";

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const InspectionsListPage = (props) => {
  const pageSize = 21;

  const [data, setData] = useState([]);
  const [inspectionSummary, setInspectionSummary] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [mobileMapVisible, setMobileMapVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [searchdata, setSearchData] = useState([]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  localStorage.getItem("org") && localStorage.removeItem("org");
  localStorage.getItem("requestData") && localStorage.removeItem("requestData");
  useEffect(() => {
    if (searchStatus) return;

    window.appComponent.setState({
      loaderVisible: true,
    });

    document.title = "Inspections — " + process.env.REACT_APP_PAGE_TITLE;

    fetchWorkordersList();

    window.apiHelper
      .getInspectionsSummaryData()
      .then((inspectionsSummaryData) => {
        setInspectionSummary(inspectionsSummaryData);
      });
  }, [props.user, searchStatus]);

  const fetchWorkordersList = async () => {
    setLoading(true);
    try {
      const workordersList = await window.apiHelper.getWorkordersList(
        page,
        pageSize,
        searchText
      );
      setData((prevData) => [...prevData, ...workordersList.results]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(workordersList.next !== null);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  useEffect(() => {
    searchStatus && fetchSearchList();
  }, [searchText]);

  const fetchSearchList = async () => {
    setLoading(true);
    try {
      const workordersList = await window.apiHelper.getWorkordersList(
        searchPage,
        pageSize,
        searchText
      );

      setSearchData((prevSearchData) => [
        ...prevSearchData,
        ...workordersList.results,
      ]);
      setSearchPage((prevPage) => prevPage + 1);
      setHasMore(workordersList.next !== null);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSortOptionSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSearchButtonClick = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;
    setSearchStatus(true);

    // Check if the previous search text is different from the new one
    if (searchText !== inputValue) {
      setSearchData([]);
      setSearchPage(1);
    }

    setSearchText(inputValue);
  };

  const handleResetState = () => {
    setShowDropdown(false);
    setSelectedOption("");
    setSearchText("");
    setSearchStatus(false);
    if (searchStatus) {
      setData([]);
      setPage(1);
    }

    if (inputRef.current.value) {
      inputRef.current.value = "";
    }
  };

  let scrollHeight = window.innerHeight - 280;
  if (window.innerWidth <= 1199) {
    scrollHeight *= 10;
  }

  let inspectionCardsArray = [];
  if (Array.isArray(searchdata)) {
    inspectionCardsArray = searchStatus ? searchdata : data;
  }

  if (selectedOption === "date") {
    inspectionCardsArray.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateA - dateB;
    });
  } else if (selectedOption === "inspections") {
    inspectionCardsArray.sort((a, b) => {
      return a.line_number.localeCompare(b.line_number, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  }

  return (
    <div className="inspections-view">
      <Header middleHeaderComponent={"Inspections"} />
      <div className="inspections-list-content">
        <div id="main-panel">
          <div className="inspections-list-status">
            <div className="progress-footer">
              <div className="issues-details">
                <div className="details-container">
                  <h5>Inspected structures</h5>
                  <p>{inspectionSummary?.inspected_structures}</p>
                </div>
                <div className="details-container">
                  <h5>Issues Detected</h5>
                  <p>
                    {inspectionSummary?.high_level_defects +
                      inspectionSummary?.medium_level_defects}
                  </p>
                </div>
              </div>
              <div className="divider">
                <svg
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.60289 4.25L3.76795 8C4.53775 9.33333 6.46225 9.33334 7.23205 8L9.39711 4.25C10.1669 2.91667 9.20467 1.25 7.66507 1.25L3.33494 1.25C1.79534 1.25 0.833084 2.91666 1.60289 4.25Z"
                    stroke="rgba(39, 197, 155, 1)"
                    strokeWidth="2"
                  />
                </svg>
                <div className="vertical-line"></div>
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_57_772" fill="white">
                    <rect width="9" height="9" rx="1" />
                  </mask>
                  <rect
                    width="9"
                    height="9"
                    rx="1"
                    stroke="rgba(39, 197, 155, 1)"
                    strokeWidth="4"
                    mask="url(#path-1-inside-1_57_772)"
                  />
                </svg>
              </div>
              <div className="high-risk">
                <h5>
                  Number of <br /> High risk structures
                </h5>
                <p>{inspectionSummary?.high_risk_structures}</p>
              </div>
            </div>
          </div>
          <div className="inspections-list-search-filter">
            <div>
              <form
                action=""
                autoComplete="off"
                className="form-group search-group search"
                onSubmit={handleSearchButtonClick}
                onBlur={handleSearchButtonClick}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for inspections"
                  id="annotation-search"
                  ref={inputRef}
                />
                <button onClick={handleSearchButtonClick}>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.0058 14C5.13982 14 2.0058 10.866 2.0058 6.99997C2.0058 3.13396 5.13979 0 9.0058 0C12.8718 0 16.0058 3.13399 16.0058 7C16.0058 10.866 12.8718 14 9.0058 14ZM9.0058 2.00001C6.24437 2.00001 4.00581 4.23857 4.00581 7C4.00581 9.76143 6.24437 12 9.0058 12C11.7672 12 14.0058 9.76143 14.0058 7C14.0058 4.23857 11.7672 2.00001 9.0058 2.00001Z"
                      fill="#CACACA"
                    />
                    <path
                      d="M1.0058 16C0.739987 16.0015 0.484514 15.8972 0.295804 15.71C-0.0963325 15.321 -0.0989176 14.6879 0.290014 14.2958C0.291944 14.2938 0.293874 14.2919 0.295804 14.29L4.05579 10.53C4.46172 10.1379 5.10867 10.1491 5.50081 10.555C5.89295 10.9609 5.88174 11.6079 5.47582 12L1.7158 15.71C1.52709 15.8972 1.27161 16.0015 1.0058 16Z"
                      fill="#CACACA"
                    />
                  </svg>
                </button>
              </form>
            </div>
            <div>
              <div className="button-dropdown-container" ref={dropdownRef}>
                <button className="filter-button" onClick={toggleDropdown}>
                  <img src={Sort} alt="Image Alt Text" className="button-img" />
                  <span>Sort By</span>
                </button>
                {showDropdown && (
                  <div className="dropdown-box">
                    <SingleDropdown
                      options={inspectionsSortOptions}
                      optionSelected={handleSortOptionSelect}
                      toggleDropdown={toggleDropdown}
                      dropdownRef={dropdownRef}
                    />
                  </div>
                )}
              </div>
              <Tooltip title="Reset Filters" arrow placement="top">
                <div className="button-dropdown-container">
                  <button className="reset-button" onClick={handleResetState}>
                    <img src={Reset} alt="reset" className="button-img-reset" />
                  </button>
                </div>
              </Tooltip>
            </div>
          </div>

          <InfiniteScroll
            maxHeight={scrollHeight}
            fetchWorkordersList={
              searchStatus ? fetchSearchList : fetchWorkordersList
            }
            loading={loading}
            hasMore={hasMore}
          >
            <div className="inspection-cards-cover">
              {inspectionCardsArray.length > 0 &&
                Object.keys(inspectionCardsArray).map((id) => (
                  <InspectionCard
                    key={id.toString()}
                    id={id.toString()}
                    data={inspectionCardsArray[id]}
                  />
                ))}
            </div>
          </InfiniteScroll>
        </div>

        <InspectionsListMap mobileMapVisible={mobileMapVisible} />
      </div>
    </div>
  );
};

export default withNavigate(InspectionsListPage);
