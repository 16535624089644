import React from "react";
import { useNavigate } from "react-router-dom";

import profilePic from "../../assets/img/logo.svg";

const BarProfile = (props) => {
  const navigate = useNavigate();

  return (
    <div id="profile-pic" onClick={() => navigate("/")}>
      <img src={profilePic} className="avatar" alt="avatar" />

      <svg
        className="notification-icon d-none"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 9.9C5.502 9.9 5.1 9.498 5.1 9H6.9C6.9 9.498 6.498 9.9 6 9.9ZM9 8.4H3V7.8L3.6 7.2V5.634C3.6 4.362 4.218 3.282 5.4 3V2.7C5.4 2.358 5.658 2.1 6 2.1C6.342 2.1 6.6 2.358 6.6 2.7V3C7.782 3.282 8.4 4.368 8.4 5.634V7.2L9 7.8V8.4Z"
          fill="#FF8039"
        />
      </svg>
    </div>
  );
};

export default BarProfile;
