import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { formatDateTime, severityToText } from "../../helpers/Utils";
import InspectionViewTableRow from "../../Components/MainContent/InspectionViewTableRow";
import InspectionViewMap from "./InspectionViewMap";
import AnnotatedImage from "../../helpers/AnnotatedImage";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import InspectionsDropdown from "../../helpers/InspectionsDropdown";
import Header from "../../helpers/Header";

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const withParams = (Component) => (props) => {
  const params = useParams();
  return <Component {...props} params={params} />;
};

class InspectionViewPage extends Component {
  constructor(props) {
    super(props);

    this.scrollableNodeRef = React.createRef();

    this.state = {
      id: props.params.id,
      data: {},
      structures: [],
      searchTerm: "",
      structuresExpanded: {},
      shownAnnotation: null,
      priority: [],
      activeStructureIdentifier: null,
      tableStatus: true,
      activeButton: "Map",
    };

    window.appComponent.setState({
      loaderVisible: true,
    });

    this.onSearch = this.onSearch.bind(this);

    this.updateRemoteDate = this.updateRemoteDate.bind(this);

    this.onRowClicked = this.onRowClicked.bind(this);
    this.onStructureSelected = this.onStructureSelected.bind(this);
    this.onPopupToggle = this.onPopupToggle.bind(this);
    this.onPrioritiesChange = this.onPrioritiesChange.bind(this);
    this.onAnnotationsCollapse = this.onAnnotationsCollapse.bind(this);
    this.onIssueClicked = this.onIssueClicked.bind(this);
    this.onNextShowAnnotation = this.onNextShowAnnotation.bind(this);
    this.getNextAnnotationToShow = this.getNextAnnotationToShow.bind(this);
    this.onPrevShowAnnotation = this.onPrevShowAnnotation.bind(this);
    this.getPrevAnnotationToShow = this.getPrevAnnotationToShow.bind(this);
    this.handleActiveButton = this.handleActiveButton.bind(this);
  }

  updateRemoteDate() {
    const promiseInfo = window.apiHelper.getWorkorderInfo(this.state.id),
      promiseStructures = window.apiHelper.getWorkorderStructures(
        this.state.id
      );

    Promise.all([promiseInfo, promiseStructures]).then((values) => {
      const data = values[0],
        structures = values[1];

      document.title =
        data.linenumber + " — " + process.env.REACT_APP_PAGE_TITLE;

      this.setState(
        {
          data: data,
          structures: structures,
        },
        () => {
          window.appComponent.setState({
            loaderVisible: false,
          });
        }
      );
    });
  }

  componentDidMount() {
    Fancybox.bind("[data-fancybox]");
    this.updateRemoteDate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.id !== this.state.id) {
      this.updateRemoteDate();
    }

    if (this.state.activeButton !== prevState.activeButton) {
      window.appComponent.setState({
        loaderVisible: true,
      });

      setTimeout(() => {
        window.appComponent.setState({
          loaderVisible: false,
        });
      }, 500);

      setTimeout(() => {
        this.setState((prevState) => ({ tableStatus: !prevState.tableStatus }));
      }, 10);
    }
  }

  onSearch(e) {
    e.preventDefault();
    const searchTerm = document.querySelector("input#annotation-search").value;
    this.setState({
      searchTerm: searchTerm,
    });
  }

  toggleDropdownInspections = () => {
    this.setState((prevState) => ({
      showInspectionsDropdown: !prevState.showInspectionsDropdown,
    }));
  };

  handleActiveButton = (activeButton) => {
    if (activeButton === "Tracking") {
      this.props.navigate("/tracking" + "/" + this.state.id);
    }
  };
  /* OLD FUNCTIONS */

  onStructureSelected(structureIdentifier) {
    if (!structureIdentifier) {
      structureIdentifier = null;
    }

    if (this.state.activeStructureIdentifier !== structureIdentifier) {
      this.setState(
        {
          activeStructureIdentifier: structureIdentifier,
        },
        () => {
          if (structureIdentifier) {
            let target = document.querySelector(
              '.structure-issues[data-title="' + structureIdentifier + '"]'
            );
            if (target) {
              this.scrollableNodeRef.current.scrollTop = target.offsetTop;

              const detectedIssuesTab = target.querySelector(
                ".issues-header .detected-issues"
              );
              if (!detectedIssuesTab.classList.contains("active")) {
                detectedIssuesTab.click();
              }
            }
          }
        }
      );
    }
  }

  onPrioritiesChange(e, type) {
    e.preventDefault();

    let priority = this.state.priority;
    if (type == "") {
      priority = [];
    } else {
      const index = priority.indexOf(type);
      if (index !== -1) {
        priority.splice(index, 1);
      } else {
        priority.push(type);
      }
    }

    this.setState({
      priority: priority,
    });
  }

  onAnnotationsCollapse(e, structureIdentifier, type) {
    e.preventDefault();
    e.stopPropagation();

    let structuresExpanded = this.state.structuresExpanded;
    if (
      structuresExpanded.hasOwnProperty(structureIdentifier) &&
      structuresExpanded[structureIdentifier] === type
    ) {
      structuresExpanded[structureIdentifier] = null;
    } else {
      structuresExpanded[structureIdentifier] = type;
    }

    this.setState({
      structuresExpanded: structuresExpanded,
    });
  }

  onPopupToggle(e, optionalPosition) {
    this.props.onPopupToggle(e, optionalPosition);
  }

  onRowClicked(identifier) {
    this.setState({
      activeStructureIdentifier: identifier,
    });
  }

  countPreparedPhotos() {
    let count = 0;

    this.state.data &&
      this.state.data.objects &&
      this.state.data.objects.forEach((object) => {
        count += object.photos.length;
      });

    return count;
  }

  isSearched(annotation, structureIdentifier) {
    if (!this.state.searchTerm || !annotation) {
      return true;
    }
    const searchTerm = this.state.searchTerm.toLowerCase();

    let isSearched = false;
    if (
      (structureIdentifier &&
        structureIdentifier.toString().toLowerCase().indexOf(searchTerm) >=
          0) ||
      (annotation.feature &&
        annotation.feature.toString().toLowerCase().indexOf(searchTerm) >= 0) ||
      (annotation.defect &&
        annotation.defect.join(",").toLowerCase().indexOf(searchTerm) >= 0) ||
      (annotation.description &&
        annotation.description.toString().toLowerCase().indexOf(searchTerm) >=
          0)
    ) {
      isSearched = true;
    }
    return isSearched;
  }

  getObjectLatLngById(id) {
    let coords = {};
    for (let i = 0; i < this.state.data.objects.length; i++) {
      if (id == this.state.data.objects[i].title) {
        coords = {
          lat: this.state.data.objects[i].lat,
          lng: this.state.data.objects[i].lng,
        };
      }
    }

    return coords;
  }

  onIssueClicked(annotation, structureId) {
    this.showAnnotation(annotation, structureId);
  }

  showAnnotation(annotation, structureId, isCreation = true) {
    let that = this;

    window.apiHelper.getPhotoAnnotated(annotation.id).then((photoData) => {
      if (photoData) {
        this.setState({
          shownAnnotation: { id: annotation.id, structure: structureId },
        });
        let priority = "good";
        switch (annotation.severity) {
          case 1:
            priority = "good";
            break;
          case 2:
            priority = "low";
            break;
          case 3:
            priority = "medium";
            break;
          case 4:
            priority = "high";
            break;
        }

        const caption =
            annotation.feature + ": " + annotation.defect.join(", "),
          annotatedImage = new AnnotatedImage(photoData, priority, caption),
          content = annotatedImage.render();

        let buttons =
          '<div class="fancybox__nav"><button tabindex="0" title="Next" class="f-button is-next" data-carousel-next="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M9 3l9 9-9 9"></path></svg></button><button tabindex="0" title="Previous" class="f-button is-prev" data-carousel-prev="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M15 3l-9 9 9 9"></path></svg></button></div>';

        if (isCreation) {
          Fancybox.show(
            [
              {
                src: content,
                type: "html",
                dragToClose: false,
                touch: false,
                clickSlide: false,
              },
            ],
            {
              on: {
                "*": function (fancyboxCur, eventName) {
                  if (eventName === "done") {
                    document
                      .querySelector(".fancybox__viewport")
                      .insertAdjacentHTML("beforeend", buttons);
                    document
                      .querySelector(".fancybox__container .is-next")
                      .addEventListener("click", (e) => {
                        that.onNextShowAnnotation(e, fancyboxCur);
                      });
                    document
                      .querySelector(".fancybox__container .is-prev")
                      .addEventListener("click", (e) => {
                        that.onPrevShowAnnotation(e, fancyboxCur);
                      });
                  }
                },
              },
            }
          );
        } else {
          document.querySelector(
            ".fancybox__container .fancybox__content"
          ).innerHTML = content.innerHTML;
        }

        window.appComponent.setState({
          loaderVisible: false,
        });
      }
    });
  }

  onNextShowAnnotation(e, fancyboxCur) {
    e.preventDefault();
    window.appComponent.setState({ loaderVisible: true });

    let nextAnnotationToShow = this.getNextAnnotationToShow();
    if (nextAnnotationToShow) {
      this.showAnnotation(
        nextAnnotationToShow,
        this.state.shownAnnotation.structure,
        false
      );
    }
  }

  onPrevShowAnnotation(e, fancyboxCur) {
    e.preventDefault();
    window.appComponent.setState({ loaderVisible: true });

    let prevAnnotationToShow = this.getPrevAnnotationToShow();
    if (prevAnnotationToShow) {
      this.showAnnotation(
        prevAnnotationToShow,
        this.state.shownAnnotation.structure,
        false
      );
    }
  }

  getNextAnnotationToShow() {
    let nextAnnotationToShow = null,
      isNext = false,
      firstAnnotation = null;

    this.state.structures.forEach((structure) => {
      if (
        structure.identifier === this.state.shownAnnotation.structure &&
        structure.annotations
      ) {
        structure.annotations.forEach((annotation) => {
          if (
            (this.state.priority == "" ||
              this.state.priority.includes(
                severityToText(annotation.severity)
              )) &&
            this.isSearched(annotation, structure.identifier)
          ) {
            if (!firstAnnotation) {
              firstAnnotation = annotation;
            }

            if (isNext) {
              nextAnnotationToShow = annotation;
              isNext = false;
            }

            if (annotation.id === this.state.shownAnnotation.id) {
              isNext = true;
            }
          }
        });
      }
    });

    return nextAnnotationToShow ? nextAnnotationToShow : firstAnnotation;
  }

  getPrevAnnotationToShow() {
    let prevAnnotationToShow = null,
      isPrev = false,
      lastAnnotation = null;

    this.state.structures.forEach((structure) => {
      if (
        structure.identifier === this.state.shownAnnotation.structure &&
        structure.annotations
      ) {
        structure.annotations.forEach((annotation) => {
          if (
            (this.state.priority == "" ||
              this.state.priority.includes(
                severityToText(annotation.severity)
              )) &&
            this.isSearched(annotation, structure.identifier)
          ) {
            if (isPrev) {
              lastAnnotation = annotation;
            }

            if (annotation.id === this.state.shownAnnotation.id) {
              isPrev = true;
            }

            if (!isPrev) {
              prevAnnotationToShow = annotation;
            }
          }
        });
      }
    });

    return prevAnnotationToShow ? prevAnnotationToShow : lastAnnotation;
  }

  render() {
    let scrollHeight = window.innerHeight - 350;
    if (window.innerWidth <= 1199) {
      scrollHeight *= 10;
    }

    const inspection = this.state.data;
    if (!inspection) {
      return <></>;
    }

    let displayText = inspection.linenumber
      ? `${inspection.linenumber} / ${this.state.id} / `
      : "Loading... ";

    let curStructure;

    return (
      <div className="inspections-view">
        <Header
          middleHeaderComponent={
            <InspectionsDropdown
              activeButtonSelected={this.handleActiveButton}
              toggleDropdownInspections={this.toggleDropdownInspections}
              showInspectionsDropdown={this.state.showInspectionsDropdown}
              activeButton={this.state.activeButton}
              displayName={displayText}
            />
          }
          navigateBack={"inspectionsList"}
        />

        <div className="inspections-list-content">
          <div id="main-panel">
            {inspection.linenumber && (
              <>
                <div className="inspection-view">
                  {inspection.stats && (
                    <div className="inspection-issues">
                      <div className="issues-shortcut">
                        <div className="shortcut-container">
                          <h5>Inspected Structures</h5>
                          <p>{inspection.stats.total_number_of_structures}</p>
                        </div>
                        <div className="shortcut-container">
                          <h5>Important Defects</h5>
                          <p>
                            {inspection.stats.medium + inspection.stats.high}
                          </p>
                        </div>
                      </div>

                      <ul className="issues-chart">
                        <li className="high-priority">
                          High
                          <span
                            data-amount={inspection.stats.high}
                            style={{
                              width:
                                (inspection.stats.high /
                                  (inspection.stats.low +
                                    inspection.stats.medium +
                                    inspection.stats.high)) *
                                  100 +
                                "%",
                            }}
                          ></span>
                        </li>
                        <li className="medium-priority">
                          Medium
                          <span
                            data-amount={inspection.stats.medium}
                            style={{
                              width:
                                (inspection.stats.medium /
                                  (inspection.stats.low +
                                    inspection.stats.medium +
                                    inspection.stats.high)) *
                                  100 +
                                "%",
                            }}
                          ></span>
                        </li>
                        <li className="low-priority">
                          Low
                          <span
                            data-amount={inspection.stats.low}
                            style={{
                              width:
                                (inspection.stats.low /
                                  (inspection.stats.low +
                                    inspection.stats.medium +
                                    inspection.stats.high)) *
                                  100 +
                                "%",
                            }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div
                    className={
                      this.state.moreInfoVisible
                        ? "details-cover active"
                        : "details-cover"
                    }
                  >
                    <div className="divider">
                      <svg
                        width="11"
                        height="10"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.60289 4.25L3.76795 8C4.53775 9.33333 6.46225 9.33334 7.23205 8L9.39711 4.25C10.1669 2.91667 9.20467 1.25 7.66507 1.25L3.33494 1.25C1.79534 1.25 0.833084 2.91666 1.60289 4.25Z"
                          stroke="rgba(39, 197, 155, 1)"
                          strokeWidth="2"
                        />
                      </svg>

                      <svg
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask id="path-1-inside-1_57_772" fill="white">
                          <rect width="9" height="9" rx="1" />
                        </mask>
                        <rect
                          width="9"
                          height="9"
                          rx="1"
                          stroke="rgba(39, 197, 155, 1)"
                          strokeWidth="4"
                          mask="url(#path-1-inside-1_57_772)"
                        />
                      </svg>
                    </div>

                    <div className="inspection-details">
                      {(inspection.started_at && (
                        <>
                          <div className="details-container">
                            <h5>Start Time</h5>
                            <p>{formatDateTime(inspection.started_at)}</p>
                          </div>
                          <div className="details-container">
                            <h5>End Time</h5>
                            <p>{formatDateTime(inspection.completed_at)}</p>
                          </div>
                        </>
                      )) || (
                        <>
                          <div className="details-container">
                            <h5>Created At</h5>
                            <p>{formatDateTime(inspection.created_at)}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="inspection-data">
                  <div className="data-filters">
                    <ul>
                      <li
                        className={this.state.priority == [] ? "active" : ""}
                        onClick={(e) => this.onPrioritiesChange(e, "")}
                      >
                        All Priorities
                      </li>
                      <li
                        className={
                          this.state.priority.includes("high")
                            ? "high-priority active"
                            : "high-priority"
                        }
                        onClick={(e) => this.onPrioritiesChange(e, "high")}
                      >
                        High
                      </li>
                      <li
                        className={
                          this.state.priority.includes("medium")
                            ? "medium-priority active"
                            : "medium-priority"
                        }
                        onClick={(e) => this.onPrioritiesChange(e, "medium")}
                      >
                        Medium
                      </li>
                      <li
                        className={
                          this.state.priority.includes("low")
                            ? "low-priority active"
                            : "low-priority"
                        }
                        onClick={(e) => this.onPrioritiesChange(e, "low")}
                      >
                        Low
                      </li>
                      <li
                        className={
                          this.state.priority.includes("good")
                            ? "good-priority active"
                            : "good-priority"
                        }
                        onClick={(e) => this.onPrioritiesChange(e, "good")}
                      >
                        Good
                      </li>
                    </ul>

                    <form
                      action=""
                      autoComplete="off"
                      className="form-group search-group"
                      onSubmit={this.onSearch}
                      onBlur={this.onSearch}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="annotation-search"
                        placeholder="Search..."
                        autoComplete="off"
                      />
                      <button>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.0058 14C5.13982 14 2.0058 10.866 2.0058 6.99997C2.0058 3.13396 5.13979 0 9.0058 0C12.8718 0 16.0058 3.13399 16.0058 7C16.0058 10.866 12.8718 14 9.0058 14ZM9.0058 2.00001C6.24437 2.00001 4.00581 4.23857 4.00581 7C4.00581 9.76143 6.24437 12 9.0058 12C11.7672 12 14.0058 9.76143 14.0058 7C14.0058 4.23857 11.7672 2.00001 9.0058 2.00001Z"
                            fill="#CACACA"
                          />
                          <path
                            d="M1.0058 16C0.739987 16.0015 0.484514 15.8972 0.295804 15.71C-0.0963325 15.321 -0.0989176 14.6879 0.290014 14.2958C0.291944 14.2938 0.293874 14.2919 0.295804 14.29L4.05579 10.53C4.46172 10.1379 5.10867 10.1491 5.50081 10.555C5.89295 10.9609 5.88174 11.6079 5.47582 12L1.7158 15.71C1.52709 15.8972 1.27161 16.0015 1.0058 16Z"
                            fill="#CACACA"
                          />
                        </svg>
                      </button>
                    </form>
                  </div>

                  {this.state.tableStatus && (
                    <SimpleBar
                      scrollableNodeProps={{
                        ref: this.scrollableNodeRef,
                      }}
                      style={{
                        maxHeight: scrollHeight,
                        overflowX: "hidden",
                        paddingRight: 13,
                        marginRight: -13,
                      }}
                      autoHide={true}
                    >
                      <div className="inspection-issues">
                        <div className="inspection-issues-header">
                          <div>Priority</div>
                          <div>Structures</div>
                        </div>

                        <div className="inspection-issues-body">
                          {this.state.structures &&
                            this.state.structures.map(
                              (structure, i, structures) => {
                                if (
                                  structure.annotations &&
                                  structure.annotations.length > 0
                                ) {
                                  // Calculate defect count
                                  let defectCount = 0;

                                  for (const annotation of structure.annotations) {
                                    if (
                                      (this.state.priority.length === 0 ||
                                        this.state.priority.includes(
                                          severityToText(annotation.severity)
                                        )) &&
                                      this.isSearched(
                                        annotation,
                                        structure.identifier
                                      ) &&
                                      severityToText(annotation.severity) !==
                                        "good"
                                    ) {
                                      defectCount++;
                                    }
                                  }
                                  // Calculate inspected components count
                                  let inspectedComponentCount = 0;

                                  for (const annotation of structure.annotations) {
                                    if (
                                      (this.state.priority.length === 0 ||
                                        this.state.priority.includes(
                                          severityToText(annotation.severity)
                                        )) &&
                                      this.isSearched(
                                        annotation,
                                        structure.identifier
                                      ) &&
                                      severityToText(annotation.severity) ===
                                        "good"
                                    ) {
                                      inspectedComponentCount++;
                                    }
                                  }
                                  let outputs = [];
                                  for (const annotation of structure.annotations) {
                                    if (
                                      (this.state.priority.length === 0 ||
                                        this.state.priority.includes(
                                          severityToText(annotation.severity)
                                        )) &&
                                      this.isSearched(
                                        annotation,
                                        structure.identifier
                                      )
                                    ) {
                                      let results = (
                                        <InspectionViewTableRow
                                          key={annotation?.id.toString()}
                                          data={annotation}
                                          defectCount={defectCount}
                                          inspectedComponentCount={
                                            inspectedComponentCount
                                          }
                                          withHeader={
                                            !(
                                              curStructure &&
                                              curStructure.identifier ===
                                                structure.identifier
                                            )
                                          }
                                          isActive={
                                            this.state
                                              .activeStructureIdentifier &&
                                            structure.identifier ===
                                              this.state
                                                .activeStructureIdentifier
                                          }
                                          onRowClicked={this.onRowClicked}
                                          structure={structure}
                                          onAnnotationsCollapse={
                                            this.onAnnotationsCollapse
                                          }
                                          expandedType={
                                            this.state.structuresExpanded[
                                              structure?.identifier
                                            ]
                                          }
                                          filteredPriorities={
                                            this.state.priority
                                          }
                                          onIssueClicked={this.onIssueClicked}
                                        />
                                      );
                                      curStructure = structure;

                                      outputs.push(results);
                                    }
                                  }

                                  return outputs;
                                }
                              }
                            )}
                        </div>
                      </div>
                    </SimpleBar>
                  )}
                </div>
              </>
            )}
          </div>

          <InspectionViewMap
            structures={this.state.structures}
            priority={this.state.priority}
            searchTerm={this.state.searchTerm}
            activeStructureIdentifier={this.state.activeStructureIdentifier}
            mobileMapVisible={this.state.mobileMapVisible}
            onStructureSelected={this.onStructureSelected}
          />
        </div>
      </div>
    );
  }
}

export default withParams(withNavigate(InspectionViewPage));
