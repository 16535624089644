import React, { useState, useEffect, useRef, useContext } from "react";
import Delete from "../assets/img/icons/delete.png";
import Deselect from "../assets/img/icons/deselect.png";
import Rearrange from "../assets/img/icons/rearrange.png";
import Close from "../assets/img/icons/cancel.png";
import Map from "../assets/img/icons/map.png";
import annotated from "../assets/img/annotated.svg";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import PopupListImage from "./PopupListImage";
import Tooltip from "@mui/material/Tooltip";
import Popup from "./Popup";
import { showNotificationToast } from "./Messages";
import MapComponent from "../Components/Map/MapComponent";
import AuthContext from "../pages/auth/AuthContext";
import { ReactSVG } from "react-svg";

const imageListHeight = 755;
const mapImagesListHeight = 335;

const PopupList = ({
  handleHidePopup,
  images,
  hoveredItemId,
  setHoveredItemId,
  setSelectedItemId,
  selectedItemId,
  selectedImageDetails,
  setDeleteImages,
  setReassignImages,
}) => {
  const authContext = useContext(AuthContext);

  const urlParts = window.location.pathname.split("/");
  const inspectionsIndex = urlParts.indexOf("tracking");

  const requestId = urlParts[inspectionsIndex + 1];
  const structureNo = urlParts[inspectionsIndex + 2];
  const structureId = urlParts[inspectionsIndex + 3];

  const imagesListRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedFilenames, setCheckedFilenames] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [reassignPopup, setReassignPopup] = useState(false);
  const [structureList, setStructureList] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState(structureId);

  const imagesContainerRef = useRef(null);
  const [mapToolHeight, setMapToolHeight] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [imagesHeight, setImagesHeight] = useState(imageListHeight);

  useEffect(() => {
    const updateMapToolHeight = () => {
      if (imagesContainerRef.current) {
        const containerHeight = imagesContainerRef.current.offsetHeight;
        setMapToolHeight(containerHeight - imagesHeight);
      }
    };

    updateMapToolHeight();
    window.addEventListener("resize", updateMapToolHeight);

    return () => {
      window.removeEventListener("resize", updateMapToolHeight);
    };
  }, [showMap]);

  useEffect(() => {
    setTimeout(() => {
      // Scroll to selected image when it changes
      if (selectedItemId && imagesListRef.current) {
        const selectedImageElement = imagesListRef.current.querySelector(
          `#image-${selectedItemId}`
        );
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }
      }
    }, 400);
  }, [selectedItemId]);

  if (selectedItemId === undefined || selectedItemId === "") {
    return null;
  }

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleItemSelect = (itemId) => {
    setSelectedItemId(itemId);
  };

  const handleCheckboxChange = (id, filename) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      const newCheckedFilenames = [...checkedFilenames];

      if (newCheckedItems[id]) {
        delete newCheckedItems[id];
        const index = newCheckedFilenames.indexOf(filename);
        if (index > -1) {
          newCheckedFilenames.splice(index, 1);
        }
        setSelectedCount((prevCount) => prevCount - 1);
      } else {
        newCheckedItems[id] = true;
        newCheckedFilenames.push(filename);
        setSelectedCount((prevCount) => prevCount + 1);
      }
      setCheckedFilenames(newCheckedFilenames);
      return newCheckedItems;
    });
  };

  const handleDeselectAll = () => {
    if (selectedCount !== 0) {
      setCheckedItems({});
      setSelectedCount(0);
    }
  };

  const isCardSelected = (id) => {
    return checkedItems[id];
  };

  const handleDeletePopup = () => {
    selectedCount !== 0 && setDeletePopup(true);
  };

  const handleDeletePopupAccept = () => {
    const idsArray = Object.keys(checkedItems);
    const data = {
      ids: idsArray,
    };

    handleSoftDeleteImages(data);
  };

  const handleSoftDeleteImages = async (data) => {
    try {
      const response = await window.apiHelper.postImagesSoftDelete(data);
      if (response) {
        setDeleteImages(true);
        setDeletePopup(false);
        showNotificationToast(
          ` ${selectedCount} ${selectedCount > 1 ? "images" : "image"} deleted `
        );
        setSelectedCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowMap = () => {
    setShowMap(!showMap);
    if (!showMap) {
      setImagesHeight(mapImagesListHeight);
    } else {
      setImagesHeight(imageListHeight);
    }
  };

  const handleDeletePopupReject = () => {
    setDeletePopup(false);
  };

  const handleReassignPopup = () => {
    if (selectedCount !== 0) {
      setReassignPopup(true);
      showStructureIds(requestId);
    }
  };

  const showStructureIds = async (requestId) => {
    try {
      const response = await window.apiHelper.getStructureIds(requestId);
      if (response) {
        const extractedData = response.map((item) => ({
          structure_id: item.structure_id,
          id: item.id,
          name: item.structure__identifier,
        }));
        setStructureList(extractedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReassignPopupAccept = () => {
    const idsArray = Object.keys(checkedItems);
    const data = {
      image_ids: idsArray,
      destination_structure_id: selectedStructure[0].structure_id,
    };
    handleReorganizeImages(data);
  };

  const handleReorganizeImages = async (data) => {
    try {
      const response = await window.apiHelper.postRestructingImages(data);
      if (response) {
        setReassignImages(true);
        setReassignPopup(false);
        showNotificationToast(
          ` ${selectedCount} ${
            selectedCount > 1 ? "images" : "image"
          } reassigned to ${selectedStructure[0].name} `
        );
        setSelectedCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReassignPopupReject = () => {
    setReassignPopup(false);
  };

  return (
    <div>
      <div className="popUpWindow">
        {deletePopup && (
          <Popup
            text="Are you sure you want to delete the images"
            onAccept={handleDeletePopupAccept}
            onReject={handleDeletePopupReject}
            type="option"
          />
        )}

        {reassignPopup && (
          <Popup
            type="dropdown"
            onAccept={handleReassignPopupAccept}
            onReject={handleReassignPopupReject}
            placeholder={"Structure No."}
            options={structureList}
            text="Choose structure to link to"
            defaultValue={structureNo}
            selectedOption={setSelectedStructure}
          />
        )}
        <div className="button-header">
          <div className="button-header-left">
            {authContext.permissions.includes("can_delete_images") && (
              <button className="filter-button" onClick={handleDeletePopup}>
                <img src={Delete} alt="delete" className="button-img" />

                <span>
                  {"Delete Photos"}
                  {selectedCount > 0 ? ` (${selectedCount})` : ""}
                </span>
              </button>
            )}
            {authContext.permissions.includes("can_reassign_images") && (
              <button className="filter-button" onClick={handleReassignPopup}>
                <img src={Rearrange} alt="rearrange" className="button-img" />

                <span>
                  {"Reassign Photos"}
                  {selectedCount > 0 ? ` (${selectedCount})` : ""}
                </span>
              </button>
            )}

            <button
              className="filter-button"
              onClick={handleDeselectAll}
              disabled={selectedCount === 0}
            >
              <img src={Deselect} alt="deselect" className="button-img" />
              <span>{"Deselect All"}</span>
            </button>

            <button className="filter-button" onClick={handleShowMap}>
              <img src={Map} alt="map" className="button-img" />
              <span>{`${!showMap ? `Show` : `Hide`} Map`}</span>
            </button>
          </div>
          <div className="button-header-right">
            <button className="close-button" onClick={handleHidePopup}>
              <img src={Close} alt="close" className="close-img" />
            </button>
          </div>
        </div>
        <div className="images-container" ref={imagesContainerRef}>
          <div className="images-container-left">
            <div className="images-list" ref={imagesListRef}>
              <SimpleBar
                style={{
                  maxHeight: imagesHeight,
                  marginRight: 5,
                }}
              >
                <div className="images-list-cover">
                  {images.map((item, index) => (
                    <Tooltip
                      key={index}
                      title={item.filename}
                      style={{ cursor: "pointer" }}
                      followCursor
                    >
                      <div
                        key={index}
                        id={`image-${item.id}`}
                        className={`card-v2 ${
                          hoveredItemId === item.id ? "hovered" : ""
                        } ${selectedItemId === item.id ? "selected" : ""} ${
                          isCardSelected(item.id) ? "checked" : ""
                        }`}
                        onMouseOver={() => handleItemHover(item.id)}
                        onMouseOut={() => handleItemHover(null)}
                        onClick={() => handleItemSelect(item.id)}
                      >
                        <>
                          <img
                            src={item.thumbnail}
                            alt="thumbnail"
                            className="image-thumbnail loaded"
                          />
                          {(isCardSelected(item.id) ||
                            hoveredItemId === item.id) && (
                            <div className="thumbnail-checkbox-position">
                              <input
                                type="checkbox"
                                checked={!!checkedItems[item.id]}
                                onChange={() =>
                                  handleCheckboxChange(item.id, item.filename)
                                }
                                className="thumbnail-checkbox"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          )}
                        </>
                        {item.annotated && (
                          <ReactSVG
                            src={annotated}
                            className={`annotated-sign annotated-sign-${item.max_severity}`}
                          />
                        )}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </SimpleBar>
            </div>
            {showMap && (
              <div className="map-tool-container">
                <MapComponent
                  selectedItemId={selectedItemId}
                  checkedItems={checkedItems}
                  selectedCount={selectedCount}
                  setSelectedCount={setSelectedCount}
                  setReassignImages={setReassignImages}
                  checkedFilenames={checkedFilenames}
                  mapToolHeight={mapToolHeight}
                  type={"reassign"}
                />
              </div>
            )}
          </div>

          <PopupListImage
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedImageDetails={selectedImageDetails}
            selectedItemId={selectedItemId}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupList;
