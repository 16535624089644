import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactSVG } from "react-svg";
import arrowBack from "../assets/img/arrow-back.svg";
import notification from "../assets/img/notification.svg";
import upload from "../assets/img/upload.svg";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../GlobalContext";
import { showNotificationToast } from "../helpers/Messages";
import NotificationsList from "./NotificationList";
import AuthContext from "../pages/auth/AuthContext";

const Header = (props) => {
  const authContext = useContext(AuthContext);
  const { setOrg, setRequestData } = useGlobal();
  const pageSize = 10;

  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showNotificationsCount, setShowNotificationsCount] = useState(0);

  const [isFirstAPICall, setIsFirstAPICall] = useState(true);
  const [bulkRead, setBulkRead] = useState(false);
  const [previousNotifications, setPreviousNotifications] = useState([]);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const notificationRef = useRef(null);

  // TODO: add general notifications support
  // Function to fetch notifications and update state
  const fetchNotifications = async () => {
    try {
      const notificationData = await window.apiHelper.getChatNotifications(
        1,
        pageSize
      );
      bulkRead && setNotifications(notificationData.results);

      // Calculate the unread notifications count
      const unreadNotificationCount = notificationData.results.reduce(
        (total, item) => total + item.unread_count,
        0
      );

      // Check for the first API call or if the unread notifications count has changed
      if (
        !isFirstAPICall &&
        unreadNotificationCount !== showNotificationsCount
      ) {
        const newNotifications = notificationData.results.filter(
          (notification) =>
            notification.unread_count > 0 &&
            !isNotificationInPreviousList(notification)
        );
        setNotifications(notificationData.results);
        if (newNotifications.length > 0) {
          showNewNotificationsToast(newNotifications);
        }
      }
      setShowNotificationsCount(notificationData.total_unread_count);
      setIsFirstAPICall(false);
      bulkRead && setBulkRead(false);
      setPreviousNotifications(notificationData.results);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const fetchScrollNotifications = async () => {
    setLoading(true);
    try {
      const notificationData = await window.apiHelper.getChatNotifications(
        page,
        pageSize
      );
      setNotifications((prevData) => [
        ...prevData,
        ...notificationData.results,
      ]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(notificationData.results.length >= pageSize);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScrollNotifications();
  }, []);

  useEffect(() => {
    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 10000);

    return () => clearInterval(intervalId);
  }, [isFirstAPICall, showNotificationsCount, bulkRead]);

  // Function to check if a notification is in the previous list
  const isNotificationInPreviousList = (newNotification) => {
    return previousNotifications.some(
      (prevNotification) =>
        prevNotification.image_id === newNotification.image_id &&
        prevNotification.unread_count === newNotification.unread_count
    );
  };

  const showNewNotificationsToast = (newNotifications) => {
    newNotifications.length > 0 &&
      newNotifications.forEach((notification) => {
        const toastContent = (
          <div
            className="notification-toast"
            onClick={() => handleNotificationClick(notification)}
          >
            {notification.unread_count > 0
              ? `${notification.unread_count} new `
              : `${notification.total_count} `}
            {`messages on inspection: ${notification.request_id} / ${notification.structure}`}
          </div>
        );

        showNotificationToast(toastContent);
      });
  };

  const handleNotificationClick = (notification) => {
    redirectLink(notification);
  };

  const isTrackingURL = (url) => {
    return url.startsWith("/tracking/") && url.split("/").length === 6;
  };

  const redirectLink = async (notification) => {
    window.appComponent.setState({
      loaderVisible: true,
    });
    const data = {
      image_id: notification.image_id,
    };
    try {
      const response = await window.apiHelper.readAllImageChatNotifications(
        data
      );

      if (response) {
        const locationUrl = window.location.pathname;

        const parsedURL = new URL(notification.redirect_url);
        navigate(parsedURL.pathname);
        getOrgName(parsedURL, isTrackingURL(locationUrl));
      } else {
        console.log("error, try again");
      }
    } catch (e) {
      console.log(e);
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  const getOrgName = async (parsedURL, reloadStatus) => {
    try {
      const requests = await window.apiHelper.getCompleteWorkordersList();

      if (requests) {
        const urlParts = parsedURL.pathname.split("/");
        const inspectionsIndex = urlParts.indexOf("tracking");

        const requestId = parseInt(urlParts[inspectionsIndex + 1]);

        const filteredArray = requests.filter((item) => item.id === requestId);
        setOrg(filteredArray[0].org);

        // Reload the page
        reloadStatus && window.location.reload();
      }
    } catch (e) {
      console.log(e);
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotifications(false);
      }
    };

    // Attach the event listener conditionally based on `showNotifications`
    if (showNotifications) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component unmounts or `showNotifications` changes
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotifications]);

  const handleBackToInspectionsList = () => {
    const url = window.location.pathname.split("/").slice(0, 3).join("/");
    navigate(url);
  };

  const handleBackToInspectionsPage = () => {
    navigate("/");
  };

  const handleBackClick = () => {
    if (props.navigateBack === "inspectionsList") {
      handleBackToInspectionsPage();
    } else if (props.navigateBack === "trackingPage") {
      handleBackToInspectionsList();
    }
  };

  const toggleNotifications = (event) => {
    event.stopPropagation(); // Prevent event from propagating to document
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);
  };

  const handleUploadButtonClick = () => {
    getRequestData(window.location);
  };

  const getRequestData = async (parsedURL) => {
    window.appComponent.setState({
      loaderVisible: true,
    });
    try {
      const requests = await window.apiHelper.getCompleteWorkordersList();

      if (requests) {
        const urlParts = parsedURL.pathname.split("/");

        const inspectionsIndex = urlParts.indexOf("");

        const requestId = parseInt(urlParts[inspectionsIndex + 2]);

        const filteredArray = requests.filter((item) => item.id === requestId);
        setRequestData(filteredArray);
        filteredArray.length > 0 && navigate("/uploads/images");
      }
    } catch (e) {
      window.appComponent.setState({
        loaderVisible: false,
      });
      console.log(e);
    }
  };
  return (
    <div className="header">
      {props.navigateBack ? (
        <button className="back" onClick={handleBackClick}>
          <ReactSVG src={arrowBack} />
        </button>
      ) : (
        <span>{}</span>
      )}
      {props.middleHeaderComponent}
      <div className="rightHeaderComponent">
        {window.location.pathname !== "/" &&
          authContext.permissions.includes("can_upload_images") && (
            <div className="upload-box">
              <button className="upload" onClick={handleUploadButtonClick}>
                <ReactSVG src={upload} />
              </button>
            </div>
          )}
        {authContext.permissions.includes("can_chat") ? (
          <div className="notification-box" ref={notificationRef}>
            <button className="notification" onClick={toggleNotifications}>
              <ReactSVG src={notification} />
            </button>
            {showNotificationsCount > 0 && (
              <div className="circle">{showNotificationsCount}</div>
            )}
            {showNotifications && (
              <NotificationsList
                redirectLink={redirectLink}
                notifications={notifications}
                fetchNotifications={fetchNotifications}
                fetchScrollNotifications={fetchScrollNotifications}
                loading={loading}
                hasMore={hasMore}
                setBulkRead={setBulkRead}
              />
            )}
          </div>
        ) : (
          <div>{""}</div>
        )}
      </div>
    </div>
  );
};

export default Header;
