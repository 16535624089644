// InspectionsDropdown.js

import React from "react";
import Chevron from "../assets/img/icons/chevron.png";
import InspectionsFilterDropdown from "../pages/inspections/InspectionsFilterDropdown";

const InspectionsDropdown = ({
  activeButtonSelected,
  toggleDropdownInspections,
  showInspectionsDropdown,
  activeButton,
  displayName,
}) => {
  return (
    <div className="button-dropdown-container">
      {displayName}
      <div className="dropdown-wrapper">
        <button
          className="inspection-dropdown"
          onClick={toggleDropdownInspections}
        >
          <span>{activeButton}</span>
          <img
            src={Chevron}
            alt="Inspections Alt Text"
            className={`inspections-dropdown-img ${
              showInspectionsDropdown ? "rotate-180" : ""
            }`}
          />
        </button>
        {showInspectionsDropdown && (
          <div className="dropdown-box">
            <InspectionsFilterDropdown
              activeButtonSelected={activeButtonSelected}
              toggleDropdownInspections={toggleDropdownInspections}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InspectionsDropdown;
