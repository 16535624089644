import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ImageCarousal from "./ImageCarousal";
import AnnotationWindow from "./AnnotationWindow";
import ChatBox from "./ChatBox";
import ImagePosition from "./ImagePosition";
import StructureSummary from "./StructureSummary";
import "simplebar-react/dist/simplebar.min.css";
import { useGlobal } from "../../GlobalContext";
import Header from "../../helpers/Header";
import NavigationComponent from "../../helpers/NavigationComponent";
import Loader from "../../helpers/Loader";
import AuthContext from "../auth/AuthContext";
import MapComponent from "../../Components/Map/MapComponent";
import Info from "./Info";

const AnnotationDetails = () => {
  const authContext = useContext(AuthContext);

  const { setOrg } = useGlobal();
  const urlParts = window.location.pathname.split("/");
  const inspectionsIndex = urlParts.indexOf("tracking");
  const navigate = useNavigate();

  const requestId = urlParts[inspectionsIndex + 1];
  const structureNo = urlParts[inspectionsIndex + 2];
  const structureId = urlParts[inspectionsIndex + 3];
  const imageId = urlParts[inspectionsIndex + 4];

  const [selectedRequestId, setSelectedRequestId] = useState(requestId);
  const [selectedStructureNo, setSelectedStructureNo] = useState(structureNo);
  const [selectedStructureId, setSelectedStructureId] = useState(structureId);
  const [selectedItemId, setSelectedItemId] = useState(imageId);

  const [selectedImageDetails, setSelectedImageDetails] = useState(null);

  const [summarySelected, setSummarySelected] = useState("defects");

  const [images, setImages] = useState([]);

  const [structureTracker, setStructureTracker] = useState({});

  const [annotationWindowLoad, setAnnotationWindowLoad] = useState(true);
  const [imageCarousalLoad, setImageCarousalLoad] = useState(true);
  const [structureSummaryLoad, setStructureSummaryLoad] = useState(true);
  const [dronePositionLoad, setDronePositionLoad] = useState(true);

  const [deleteImages, setDeleteImages] = useState(false);
  const [reassignImages, setReassignImages] = useState(false);

  const [noimages, setNoimages] = useState(false);
  const [mapContainerSize, setMapContainerSize] = useState({
    width: 0,
    height: 0,
  });

  const [access, setAccess] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchaccess = await window.apiHelper.getWorkorderInfo(
          selectedRequestId
        );
        fetchaccess && setAccess(true);
      } catch (err) {
        setAccess(false);
        err.response.status === 404 && navigate("/accessdenied");
      }
    };
    fetchData();
  }, []);

  // to handle image carousal pop-up operations
  useEffect(() => {
    const fetchData = async () => {
      try {
        const images = await window.apiHelper.getImagesList(
          selectedRequestId,
          selectedStructureId
        );
        setImages(images);

        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          images[0].id
        );
        let newId = imageDetails.id;
        setSelectedItemId(newId);

        const structureTracker = await window.apiHelper.getStructureTracker(
          selectedRequestId,
          selectedStructureId
        );

        setStructureTracker(structureTracker);
      } catch (e) {
        console.log(e);
      }
    };
    if (deleteImages || reassignImages) {
      fetchData();
      deleteImages && setDeleteImages(false);
      reassignImages && setReassignImages(false);
    }
  }, [deleteImages, reassignImages]);

  // get summary data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setStructureSummaryLoad(true);
        const fetchedStructureTracker =
          await window.apiHelper.getStructureTracker(
            selectedRequestId,
            selectedStructureId
          );

        setStructureTracker(fetchedStructureTracker);
        setStructureSummaryLoad(false);
      } catch (error) {
        console.error("Error fetching structure tracker:", error);
        setStructureTracker({});
        setStructureSummaryLoad(false);
        setAnnotationWindowLoad(false);
        setDronePositionLoad(false);
        let url = window.location.pathname;
        if (url.endsWith("/")) {
          url = url.slice(0, -1);
        }
        navigate(url);
      }
    };
    fetchData();
  }, [selectedStructureId]);

  // get images list
  useEffect(() => {
    window.appComponent.setState({
      loaderVisible: false,
    });
    const fetchData = async () => {
      try {
        setImageCarousalLoad(true);
        const fetchedImages = await window.apiHelper.getImagesList(
          selectedRequestId,
          selectedStructureId
        );

        if (fetchedImages.length === 0) {
          setNoimages(true);
        } else {
          setNoimages(false);
        }
        setImages(fetchedImages);
        getOrgName(window.location);
        setImageCarousalLoad(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setImageCarousalLoad(false);
      }
    };
    fetchData();
  }, [selectedStructureId]);

  // get specific image details
  useEffect(() => {
    if (noimages) {
      setAnnotationWindowLoad(false);
      setDronePositionLoad(false);
      return;
    }
    const fetchData = async () => {
      try {
        setAnnotationWindowLoad(true);
        setDronePositionLoad(true);
        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          selectedItemId
        );

        setSelectedImageDetails(imageDetails);

        let presentUrl = window.location.pathname;
        let newId = imageDetails.id;

        let parts = presentUrl.split("/");
        parts[parts.length - 1] = newId;
        let newUrl = parts.join("/");

        navigate(newUrl);
        setDronePositionLoad(false);

        const annotationWindow = document.querySelector(".annotationWindow");
        const testElement = document.querySelector(".annotationImage");

        if (annotationWindow && testElement) {
          const parentHeight = annotationWindow.clientHeight;
          const aspectRatio =
            imageDetails.type === 4
              ? imageDetails.width || imageDetails.height
                ? imageDetails.width / imageDetails.height
                : 1.25
              : 4 / 3;
          const width = parentHeight * aspectRatio;

          testElement.style.width = `${width}px`;
        }
      } catch (error) {
        setAnnotationWindowLoad(false);
        setDronePositionLoad(false);
        let url = window.location.pathname;
        if (url.endsWith("/undefined")) {
          url = url.slice(0, -10);
        } else if (url.endsWith("/")) {
          url = url.slice(0, -1);
        }
        navigate(url);
      }
    };

    access && fetchData();

    const chatWindow = document.querySelector(".chatBox");

    setMapContainerSize({
      width: chatWindow.clientWidth,
      height: chatWindow.clientHeight - 70,
    });
  }, [selectedItemId, access]);

  const getOrgName = async (parsedURL) => {
    try {
      const requests = await window.apiHelper.getCompleteWorkordersList();

      if (requests) {
        const urlParts = parsedURL.pathname.split("/");
        const inspectionsIndex = urlParts.indexOf("tracking");

        const requestId = parseInt(urlParts[inspectionsIndex + 1]);

        const filteredArray = requests.filter((item) => item.id === requestId);

        setOrg(filteredArray[0].org);
      }
    } catch (e) {
      console.log(e);
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  return (
    <div className="inspections-view">
      <Header
        navigateBack={"trackingPage"}
        middleHeaderComponent={
          <NavigationComponent
            selectedRequestId={selectedRequestId}
            selectedStructureNo={selectedStructureNo}
            selectedStructureId={selectedStructureId}
            selectedItemId={selectedItemId}
            setSelectedRequestId={setSelectedRequestId}
            setSelectedStructureNo={setSelectedStructureNo}
            setSelectedStructureId={setSelectedStructureId}
            setSelectedItemId={setSelectedItemId}
            displayNavigationButtons={true}
            setAnnotationWindowLoad={setAnnotationWindowLoad}
            setDronePositionLoad={setDronePositionLoad}
          />
        }
      />
      <div className="annotation-details-content">
        <div id="main-panel-annotation">
          <div className="annotationWindow">
            <div className="annotationImage">
              {annotationWindowLoad && <Loader />}
              {noimages ? (
                <span className="select-image">No images uploaded</span>
              ) : (
                <AnnotationWindow
                  selectedImageDetails={selectedImageDetails}
                  setAnnotationWindowLoad={setAnnotationWindowLoad}
                />
              )}
            </div>
          </div>
          <div className="chatBox">
            <div className="toggle-container">
              <div
                className={`toggle-option ${
                  summarySelected === "defects" ? "selected" : ""
                }`}
                onClick={() => setSummarySelected("defects")}
              >
                Defects
              </div>

              {authContext.permissions.includes("can_chat") && (
                <div
                  className={`toggle-option ${
                    summarySelected === "chat" ? "selected" : ""
                  }`}
                  onClick={() => setSummarySelected("chat")}
                >
                  Chat
                </div>
              )}

              <div
                className={`toggle-option ${
                  summarySelected === "map" ? "selected" : ""
                }`}
                onClick={() => setSummarySelected("map")}
              >
                Map
              </div>

              {authContext.permissions.includes("can_view_info") && (
                <div
                  className={`toggle-option ${
                    summarySelected === "info" ? "selected" : ""
                  }`}
                  onClick={() => setSummarySelected("info")}
                >
                  Info
                </div>
              )}
            </div>
            {summarySelected === "map" && (
              <>
                <div
                  className="map-placement"
                  style={{
                    width: mapContainerSize.width,
                  }}
                >
                  <MapComponent
                    selectedItemId={selectedItemId}
                    mapToolHeight={mapContainerSize.height + 21}
                    type={"map"}
                    selectedRequestId={selectedRequestId}
                    selectedStructureNo={selectedStructureNo}
                    selectedStructureId={selectedStructureId}
                    setSelectedStructureNo={setSelectedStructureNo}
                    setSelectedStructureId={setSelectedStructureId}
                    setSelectedItemId={setSelectedItemId}
                    setAnnotationWindowLoad={setAnnotationWindowLoad}
                    setStructureSummaryLoad={setStructureSummaryLoad}
                  />
                </div>
              </>
            )}

            {summarySelected === "defects" && structureTracker ? (
              <>
                {structureSummaryLoad ? (
                  <Loader />
                ) : (
                  <StructureSummary
                    setSelectedItemId={setSelectedItemId}
                    structureTracker={structureTracker}
                    setStructureTracker={setStructureTracker}
                  />
                )}
              </>
            ) : null}

            {summarySelected === "chat" &&
              authContext.permissions.includes("can_chat") && (
                <ChatBox selectedItemId={selectedItemId} />
              )}

            {summarySelected === "info" && (
              <Info
                selectedStructureId={selectedStructureId}
                selectedRequestId={selectedRequestId}
              />
            )}
          </div>
          <div className="imageCarousal" style={{ borderRadius: "" }}>
            {imageCarousalLoad ? (
              <Loader />
            ) : noimages ? (
              <></>
            ) : (
              <ImageCarousal
                images={images}
                selectedItemId={selectedItemId}
                setSelectedItemId={setSelectedItemId}
                selectedImageDetails={selectedImageDetails}
                setDeleteImages={setDeleteImages}
                setReassignImages={setReassignImages}
              />
            )}
          </div>
          <div className="droneDirection">
            {dronePositionLoad ? (
              <Loader />
            ) : noimages ? (
              <></>
            ) : (
              <>
                <ImagePosition
                  positionData={selectedImageDetails?.position_data}
                  imageId={imageId}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnotationDetails;
